import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-scan-modal',
  templateUrl: './scan-modal.component.html',
  styleUrl: './scan-modal.component.scss'
})
export class ScanModalComponent implements OnInit, OnDestroy {
  scannedWalletAddress: string | null = null;
  hasPermission = false;
  BarcodeFormat = BarcodeFormat;
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined = undefined;

  constructor(
    private nzModalRef: NzModalRef,
    private nzNotificationService: NzNotificationService
  ) {}

  ngOnInit(): void {}

  // Function to check and request camera permission
  askForCameraPermission() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.hasPermission = true;
        })
        .catch((err) => {
          console.error('Camera permission denied', err);
          this.hasPermission = false;
        });

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.availableDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );

          // Auto-select the back camera (usually the best camera for scanning)
          if (this.availableDevices.length > 0) {
            this.selectedDevice = this.availableDevices[0];
          }
        })
        .catch((err) => {
          console.error('Error getting devices: ', err);
        });
    }
  }

  scanSuccess(result: string) {
    this.scannedWalletAddress = result;
    this.destroyModal();
  }

  destroyModal() {
    this.nzModalRef?.destroy(this.scannedWalletAddress);
  }

  ngOnDestroy(): void {
    this.destroyModal();
  }

  camerasNotFound() {
    this.nzNotificationService.error('Error', "Camera's Not Found");
    this.destroyModal();
  }
}
